export const portfolioChart = {
  target: {
    targetSalesVolume: 25000,
    targetCasa: 25000,
    targetTransaction: 25000,
    targetQrVolume: 25000,
  },
  data: {
    sales_volume: [
      {
        percentage: 0.2,
        value: 120540,
      },
    ],
    casa: [
      {
        percentage: 0.2,
        value: 12540,
      },
    ],
    transaction: [
      {
        percentage: 0.2,
        value: 120540,
      },
    ],
    qr_volume: [
      {
        percentage: 0.2,
        value: 10230,
      },
    ],
  },
};

export const chartSalesVolumeTransactionTypes = {
  percentage: 100,
  data: [
    {
      type: "Credit Off Us",
      value: 35.5,
    },
    {
      type: "Credit On Us",
      value: 25.5,
    },
    {
      type: "Debit On Us",
      value: 23.8,
    },
    {
      type: "Debit Off Us",
      value: 15.2,
    },
  ],
};

export const chartCASAChart = {
  percentage: 100,
  data: [
    {
      type: "COBA",
      value: 50,
    },
    {
      type: "COMBA",
      value: 25,
    },
    {
      type: "Consumer",
      value: 25,
    },
  ],
};

export const chartCASAChartBeResponse = {
  response_code: "200",
  response_message: "Success",
  data: {
    percentage: "100",
    data: {
      coba: "44.523325661744817714",
      comba: "9.253821184356484102",
      consumer: "46.222853153898698185",
    },
  },
};

export const qrTypeList = {
  qr_on_us: [
    { id: 1, name: "Mohammad Reza Yazir", sum: "800" },
    { id: 2, name: "Dimpos Simbagariang", sum: "700" },
    { id: 3, name: "Dimpos Simbagariang", sum: "700" },
    { id: 4, name: "Dimpos Simbagariang", sum: "700" },
    { id: 5, name: "Dimpos Simbagariang", sum: "700" },
  ],
  qr_off_us: [
    { id: 1, name: "Mohammad Reza Yazir", sum: "800" },
    { id: 2, name: "Dimpos Simbagariang", sum: "700" },
    { id: 3, name: "Dimpos Simbagariang", sum: "700" },
    { id: 4, name: "Dimpos Simbagariang", sum: "700" },
    { id: 5, name: "Dimpos Simbagariang", sum: "700" },
  ],
  we_chat: [
    { id: 1, name: "Mohammad Reza Yazir", sum: "800" },
    { id: 2, name: "Dimpos Simbagariang", sum: "700" },
    { id: 3, name: "Dimpos Simbagariang", sum: "700" },
    { id: 4, name: "Dimpos Simbagariang", sum: "700" },
    { id: 5, name: "Dimpos Simbagariang", sum: "700" },
  ],
  b2b: [
    { id: 1, name: "Mohammad Reza Yazir", sum: "800" },
    { id: 2, name: "Dimpos Simbagariang", sum: "700" },
    { id: 3, name: "Dimpos Simbagariang", sum: "700" },
    { id: 4, name: "Dimpos Simbagariang", sum: "700" },
    { id: 5, name: "Dimpos Simbagariang", sum: "700" },
  ],
};

export const chartType = {
  totalQR: 100,
  totalWeChatB2b: 100,
  qr: [
    { type: "On Us", value: 60 },
    { type: "Off Us", value: 40 },
  ],
  weChatB2b: [
    { type: "We Chat", value: 60 },
    { type: "B2B", value: 40 },
  ],
};

export const filterPortfolioStatistik = [
  {
    label: "Sales Volume",
    name: "Sales Volume",
  },
  {
    label: "CASA",
    name: "Casa",
  },
  {
    label: "Transaction",
    name: "Transaction",
  },
];

export const dummySelectOctoMerchant = [
  {
    date: "2022-10",
    value: "September 2022",
    label: "QR Volume",
  },
  {
    key: "qr_type",
    value: "Oktober 2022",
    label: "QR Type",
  },
  {
    key: "qr_on_us",
    value: "November 2022",
    label: "QR On Us",
  },
  {
    key: "qr_of_us",
    value: "Desember 2022",
    label: " QR Off Us",
  },
];

// DATA RESPONSE BE
export const chartSalesVolumeTransactionType = {
  response_code: "200",
  response_message: "Success",
  data: {
    percentage: "100",
    data: {
      credit_off_us: "39.117685209089321742",
      credit_on_us: "40.540794802666633538",
      debit_off_us: "17.427012959153646597",
      debit_on_us: "2.914507029090398122",
    },
  },
};

// DATA RESPONSE BE TERBARU 17 OKT 2022
export const newDataResponseChartType = {
  response_code: "200",
  response_message: "Success",
  data: {
    qr: {
      on_us: 0.0,
      off_us: 0.0,
      total_qr: 0.0,
    },
    we_chat_b2b: {
      we_chat: 0.0,
      b2b: 0.0,
      total_we_chat_b2b: 0.0,
    },
  },
};

export const dataResponseBEPortfolioChart = {
  response_code: "200",
  response_message: "Success",
  data: {
    target: {
      target_sales_volume: 0.0,
      target_casa: 0.0,
      target_transaction: 0.0,
      target_qr_volume: 0.0,
    },
    sales_volume: {
      value: 0.0,
      percentage: 0.0,
    },
    casa: {
      target_casa: 0,
      value_casa: 0.0,
    },
    transaction: {
      ts_value: "0",
      trx_percentage: 0.0,
    },
    qr_volume: {
      value: "240.5",
      qr_percentage: 0.005483871,
    },
  },
};

export const responseBEDummySelectorMonth = {
  response_code: "200",
  response_message: "Success",
  data: [
    {
      month_name: "August 2021",
    },
    {
      month_name: "August 2022",
    },
    {
      month_name: "July 2022",
    },
    {
      month_name: "June 2022",
    },
    {
      month_name: "September 2021",
    },
    {
      month_name: "September 2022",
    },
  ],
};

export const dataResponseBENewOctoMerchantQR = {
  response_code: "200",
  response_message: "Success",
  data: {
    qr_volume: [
      {
        daybatch: 1,
        dates: "01/10/2022",
        amount: "110244.42",
      },
      {
        daybatch: 2,
        dates: "02/10/2022",
        amount: "120356.32",
      },
      {
        daybatch: 3,
        dates: "03/10/2022",
        amount: "152526.06",
      },
      {
        daybatch: 4,
        dates: "04/10/2022",
        amount: "208277.34",
      },
      {
        daybatch: 5,
        dates: "05/10/2022",
        amount: "105847.72",
      },
      {
        daybatch: 6,
        dates: "06/10/2022",
        amount: "98541.78",
      },
      {
        daybatch: 7,
        dates: "07/10/2022",
        amount: "97428.16",
      },
      {
        daybatch: 8,
        dates: "08/10/2022",
        amount: "97908.11",
      },
      {
        daybatch: 9,
        dates: "09/10/2022",
        amount: "97723.56",
      },
      {
        daybatch: 10,
        dates: "10/10/2022",
        amount: "140274.34",
      },
      {
        daybatch: 11,
        dates: "11/10/2022",
        amount: "161369.1",
      },
      {
        daybatch: 12,
        dates: "12/10/2022",
        amount: "101106.83",
      },
      {
        daybatch: 13,
        dates: "13/10/2022",
        amount: "100734.18",
      },
      {
        daybatch: 14,
        dates: "14/10/2022",
        amount: "117745.76",
      },
      {
        daybatch: 15,
        dates: "15/10/2022",
        amount: "95991.03",
      },
      {
        daybatch: 16,
        dates: "16/10/2022",
        amount: "101367.7",
      },
      {
        daybatch: 17,
        dates: "17/10/2022",
        amount: "142518.4",
      },
      {
        daybatch: 18,
        dates: "18/10/2022",
        amount: "174210.48",
      },
      {
        daybatch: 19,
        dates: "19/10/2022",
        amount: "96120.51",
      },
      {
        daybatch: 20,
        dates: "20/10/2022",
        amount: "96933.09",
      },
      {
        daybatch: 21,
        dates: "21/10/2022",
        amount: "98937.26",
      },
      {
        daybatch: 22,
        dates: "22/10/2022",
        amount: "104647.71",
      },
      {
        daybatch: 23,
        dates: "23/10/2022",
        amount: "118989.85",
      },
      {
        daybatch: 24,
        dates: "24/10/2022",
        amount: "180612.56",
      },
      {
        daybatch: 25,
        dates: "25/10/2022",
        amount: "213078.56",
      },
      {
        daybatch: 26,
        dates: "26/10/2022",
        amount: "121868.42",
      },
      {
        daybatch: 27,
        dates: "27/10/2022",
        amount: "143475.78",
      },
      {
        daybatch: 28,
        dates: "28/10/2022",
        amount: "114199.6",
      },
      {
        daybatch: 29,
        dates: "29/10/2022",
        amount: "113785.41",
      },
      {
        daybatch: 30,
        dates: "30/10/2022",
        amount: "137464.62",
      },
      {
        daybatch: 31,
        dates: "31/10/2022",
        amount: "0.0",
      },
    ],
    octo_merchant: [
      {
        daybatch: 1,
        dates: "01/10/2022",
        amount: "2272.51",
      },
      {
        daybatch: 2,
        dates: "02/10/2022",
        amount: "4506.88",
      },
      {
        daybatch: 3,
        dates: "03/10/2022",
        amount: "1459.82",
      },
      {
        daybatch: 4,
        dates: "04/10/2022",
        amount: "7104.44",
      },
      {
        daybatch: 5,
        dates: "05/10/2022",
        amount: "2007.92",
      },
      {
        daybatch: 6,
        dates: "06/10/2022",
        amount: "1881.0",
      },
      {
        daybatch: 7,
        dates: "07/10/2022",
        amount: "2182.4",
      },
      {
        daybatch: 8,
        dates: "08/10/2022",
        amount: "2015.1",
      },
      {
        daybatch: 9,
        dates: "09/10/2022",
        amount: "2178.68",
      },
      {
        daybatch: 10,
        dates: "10/10/2022",
        amount: "2609.86",
      },
      {
        daybatch: 11,
        dates: "11/10/2022",
        amount: "7180.57",
      },
      {
        daybatch: 12,
        dates: "12/10/2022",
        amount: "2490.17",
      },
      {
        daybatch: 13,
        dates: "13/10/2022",
        amount: "1286.46",
      },
      {
        daybatch: 14,
        dates: "14/10/2022",
        amount: "1650.75",
      },
      {
        daybatch: 15,
        dates: "15/10/2022",
        amount: "1832.85",
      },
      {
        daybatch: 16,
        dates: "16/10/2022",
        amount: "2160.1",
      },
      {
        daybatch: 17,
        dates: "17/10/2022",
        amount: "2203.59",
      },
      {
        daybatch: 18,
        dates: "18/10/2022",
        amount: "7069.22",
      },
      {
        daybatch: 19,
        dates: "19/10/2022",
        amount: "879.09",
      },
      {
        daybatch: 20,
        dates: "20/10/2022",
        amount: "1076.68",
      },
      {
        daybatch: 21,
        dates: "21/10/2022",
        amount: "1828.07",
      },
      {
        daybatch: 22,
        dates: "22/10/2022",
        amount: "1080.45",
      },
      {
        daybatch: 23,
        dates: "23/10/2022",
        amount: "1304.68",
      },
      {
        daybatch: 24,
        dates: "24/10/2022",
        amount: "1439.97",
      },
      {
        daybatch: 25,
        dates: "25/10/2022",
        amount: "8010.05",
      },
      {
        daybatch: 26,
        dates: "26/10/2022",
        amount: "1564.16",
      },
      {
        daybatch: 27,
        dates: "27/10/2022",
        amount: "1226.62",
      },
      {
        daybatch: 28,
        dates: "28/10/2022",
        amount: "923.43",
      },
      {
        daybatch: 29,
        dates: "29/10/2022",
        amount: "1757.54",
      },
      {
        daybatch: 30,
        dates: "30/10/2022",
        amount: "712.27",
      },
      {
        daybatch: 31,
        dates: "31/10/2022",
        amount: "0.0",
      },
    ],
  },
};
