/* eslint-disable react/prop-types */
import { Axis, Chart, Interaction, Interval, Legend, Tooltip } from "bizcharts";
import PropTypes from "prop-types";
import React from "react";
import COLORS from "utils/constants/colors";
import { maxScale } from "utils/helpers/useChartConfig";

const ChartBarComp = ({ heightSize, dataChart }) => {
  const label = {
    style: {
      fill: "",
    },
  };

  return (
    <div>
      <Chart
        padding="auto"
        autoFit
        height={heightSize}
        data={dataChart}
        theme={{ maxColumnWidth: 50 }}
        scale={{
          amount: { min: 0, max: maxScale(dataChart.data || {}), nice: true },
          nice: true,
          type: "linear",
        }}
      >
        <Interaction type="element-active" />
        <Axis
          name="amount"
          grid={{
            align: "center",
            line: {
              type: "line",
              style: {
                lineWidth: 0,
              },
            },
          }}
        />
        <Axis name="name" label={label} />
        <Tooltip showCrosshairs={false} showMarkers="true" position="top" showTitle={false} />
        <Interval
          position="name*amount"
          color={[
            "name*amount*marked",
            (name, amount, marked) => {
              let color = "";
              if (marked === 1) {
                color = COLORS.GREEN_MEDIUM;
              } else if (marked === 2) {
                color = COLORS.BLUE_MEDIUM;
              } else if (marked === 3) {
                color = COLORS.RED_MEDIUM;
              } else if (marked === 4) {
                color = COLORS.PURPLE_HARD;
              } else if (marked === 5) {
                color = COLORS.SECONDARY_MEDIUM;
              } else {
                color = COLORS.CYAN;
              }
              return color;
            },
          ]}
        />
        <Legend visible={false} />
      </Chart>
    </div>
  );
};
ChartBarComp.defaultProps = {
  heightSize: 500,
};

ChartBarComp.propTypes = {
  heightSize: PropTypes.number,
};

export default ChartBarComp;
