import { Grid, makeStyles, Typography } from "@material-ui/core";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import { useQuery } from "@tanstack/react-query";
import PropTypes from "prop-types";
import React, { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

// Icons
import IconChart from "assets/icons/chart/icon-chart-line.svg";

// Components
import ChartBarComp from "components/chart/chart-bar";
import ChartLineArea from "components/chart/chart-line-area";
import { ServerErrorBoundary } from "components/general";
import TitleCharts from "components/title/title-charts";
import CardWrapper from "components/wrapper/wrapper-card";
import WrapperOutlineChart from "components/wrapper/wrapper-oultine-chart";

// Elements
import CardTitle from "elements/card/card-header/card-title";
import LegendPortfolioStatistics from "elements/legend-chart/legend-portfolio-statistics";
import LoadingSpinner from "elements/loading/spinner";
import { CustomSelect } from "elements/select";

// Integration data line chart
import { useAbortController } from "hooks/useAbortController";
import { getDataChartByDate } from "services/api/private-routes/portfolio-management/portfolio-statistic";
// Utility
import COLORS from "utils/constants/colors";
import { SIZES } from "utils/constants/fonts";

const useStyles = makeStyles(theme => ({
  typhographyStyle: {
    marginLeft: "8px",
    color: COLORS.GRAY_MEDIUM,
  },
  toggleButtonStyle: {
    padding: "2px",
    width: "91px",
    color: COLORS.PRIMARY_DARK,
    backgroundColor: "white",
    minWidth: "61px",
    height: "32px",
    borderRadius: "8px",
    borderColor: COLORS.PRIMARY_DARK,
    textTransform: "initial",
    fontSize: SIZES.REGULAR_14,
    "&.Mui-selected": {
      color: COLORS.WHITE,
      backgroundColor: COLORS.PRIMARY_DARK,
      "&:hover": {
        backgroundColor: COLORS.PRIMARY_DARK,
      },
    },
  },
  container: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "repeat(3, 1fr)",
    },
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "repeat(1, 1fr)",
    },
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "repeat(1, 1fr)",
    },
  },
  wrapper: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "flex-start",
    },
  },
  wrapperSelect: {
    display: "flex",
    justifyContent: "flex-end",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "flex-start",
      marginTop: 10,
    },
  },
  selectDropdown: {
    maxWidth: "3rem",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
}));

const initialStateDropDown = {
  label: "Sales Volume",
  value: "salesVolume",
};

const CardPortfolioStatistik = ({ filterPortfolioStatistik, dataByDivision, legendByDivision }) => {
  const classes = useStyles();
  const { signal } = useAbortController();
  const [toggleActive, setToggleActive] = useState(true);
  const [alignment, setAlignment] = useState("date");
  const [selectByDate, setSelectByDate] = useState("");
  const [dropdownValue] = useState(initialStateDropDown);

  /*  Translations */
  const { t } = useTranslation();
  const translations = useMemo(
    () => ({
      cardTitle: t("CARD.TITLE.PORTFOLIO_STATISTIK"),
      placeholder: t("SALES_VOLUME.SALES_VOLUME"),
      general: {
        byDate: t("GENERAL.BY_DATE"),
        byDivison: t("GENERAL.BY_DIVISION"),
      },
      titleCharts: {
        textTitle: {
          salesVolume: t("MENU.SALES_VOLUME"),
          menuCasa: t("MENU.CASA"),
          portofolioTransaction: t("PORTOFOLIO.TRANSACTION"),
        },
        textSubtitle: t("PORTOFOLIO.IDR_BIO"),
      },
    }),
    [t],
  );

  /*  Fetch Data */
  const dataChartByDate = useQuery(["data-chart-by-date", selectByDate || dropdownValue.value], () =>
    getDataChartByDate({ type: selectByDate || dropdownValue.value }, signal),
  );

  useEffect(() => {
    if (!selectByDate) setSelectByDate(dropdownValue.label);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectByDate, dataChartByDate, setSelectByDate]);

  /* memo Fetching */
  const CHART_BY_DATE = useMemo(() => {
    if (dataChartByDate.isError) {
      return <ServerErrorBoundary onClick={() => dataChartByDate.refetch()} title={dataChartByDate.error?.message} />;
    }
    if (dataChartByDate.isLoading) {
      return <LoadingSpinner height={500} />;
    }
    if (!dataChartByDate.isLoading && dataChartByDate.isSuccess) {
      return <ChartLineArea dataChart={dataChartByDate?.data} />;
    }
  }, [dataChartByDate]);

  const DIVISION_SALES_VOLUME = useMemo(() => {
    if (dataByDivision.isError) {
      return <ServerErrorBoundary onClick={() => dataByDivision.refetch()} title={dataByDivision.error?.message} />;
    }
    if (dataByDivision.isLoading) {
      return <LoadingSpinner height={500} />;
    }
    if (!dataByDivision.isLoading && dataByDivision.isSuccess) {
      return <ChartBarComp heightSize={345} dataChart={dataByDivision?.data?.salesVolume} />;
    }
  }, [dataByDivision]);

  const DIVISION_CASA = useMemo(() => {
    if (dataByDivision.isError) {
      return <ServerErrorBoundary onClick={() => dataByDivision.refetch()} title={dataByDivision.error?.message} />;
    }
    if (dataByDivision.isLoading) {
      return <LoadingSpinner height={500} />;
    }
    if (!dataByDivision.isLoading && dataByDivision.isSuccess) {
      return <ChartBarComp heightSize={345} dataChart={dataByDivision?.data?.casa} />;
    }
  }, [dataByDivision]);

  const DIVISION_TRANSACTION = useMemo(() => {
    if (dataByDivision.isError) {
      return <ServerErrorBoundary onClick={() => dataByDivision.refetch()} title={dataByDivision.error?.message} />;
    }
    if (dataByDivision.isLoading) {
      return <LoadingSpinner height={500} />;
    }
    if (!dataByDivision.isLoading && dataByDivision.isSuccess) {
      return <ChartBarComp heightSize={345} dataChart={dataByDivision?.data?.transaction} />;
    }
  }, [dataByDivision]);

  /* handle toggle */
  const handleAlignment = (event, newAlignment) => {
    if (newAlignment !== null) {
      setToggleActive(!toggleActive);
      setAlignment(newAlignment);
    }
  };

  const TEXT_TITLE_CHART = {
    sales_volume: t("MENU.SALES_VOLUME"),
    casa: t("MENU.CASA"),
    transaction: t("PORTOFOLIO.TRANSACTION"),
  };

  return (
    <div>
      <CardWrapper>
        <Grid container justifyContent="space-between" className={classes.container}>
          <CardTitle iconSrc={IconChart} title={translations.cardTitle} />
          <div className={classes.wrapper}>
            <ToggleButtonGroup
              size="medium"
              value={alignment}
              exclusive
              onChange={handleAlignment}
              aria-label="Demo Text Alignment"
            >
              <ToggleButton value="date" aria-label="justified" className={classes.toggleButtonStyle}>
                <Typography>{translations.general.byDate}</Typography>
              </ToggleButton>
              <ToggleButton value="division" aria-label="left aligned" className={classes.toggleButtonStyle}>
                {translations.general.byDivison}
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
          <>
            {alignment === "date" ? (
              <div className={classes.wrapperSelect}>
                <CustomSelect
                  className={classes.selectDropdown}
                  placeholder={
                    filterPortfolioStatistik.isFetching ? (
                      "Loading..."
                    ) : (
                      <span
                        style={{
                          color: "#000",
                        }}
                      >
                        {selectByDate}
                      </span>
                    )
                  }
                  data={filterPortfolioStatistik?.data ?? []}
                  disabled={filterPortfolioStatistik.isFetching}
                  setValue={val => setSelectByDate(val.name)}
                  renderItem={val => val.name}
                  renderValue={val => val.name}
                  value={selectByDate.name}
                />
              </div>
            ) : null}
          </>
        </Grid>

        {/* by date and sales volume, by date and casa, by date and transaction */}
        {alignment === "date" ? (
          <WrapperOutlineChart>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <TitleCharts
                textTitle={TEXT_TITLE_CHART[dropdownValue.label]}
                textSubtitle={selectByDate === "Transaction" ? "(.000)" : translations.titleCharts.textSubtitle}
              />
            </div>
            {CHART_BY_DATE}
          </WrapperOutlineChart>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <WrapperOutlineChart>
                <TitleCharts
                  textTitle={translations.titleCharts.textTitle.salesVolume}
                  textSubtitle={translations.titleCharts.textSubtitle}
                />
                {DIVISION_SALES_VOLUME}
              </WrapperOutlineChart>
            </Grid>
            <Grid item xs={4}>
              <WrapperOutlineChart>
                <TitleCharts
                  textTitle={translations.titleCharts.textTitle.menuCasa}
                  textSubtitle={translations.titleCharts.textSubtitle}
                />
                {DIVISION_CASA}
              </WrapperOutlineChart>
            </Grid>
            <Grid item xs={4}>
              <WrapperOutlineChart>
                <TitleCharts
                  textTitle={translations.titleCharts.textTitle.portofolioTransaction}
                  textSubtitle="(.000)"
                />
                {DIVISION_TRANSACTION}
              </WrapperOutlineChart>
            </Grid>
          </Grid>
        )}
        {alignment === "division" ? <LegendPortfolioStatistics legend={legendByDivision} /> : ""}
      </CardWrapper>
    </div>
  );
};

CardPortfolioStatistik.defaultProps = {
  filterPortfolioStatistik: [],
  dataByDivision: [],
  legendByDivision: {},
};

CardPortfolioStatistik.propTypes = {
  filterPortfolioStatistik: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  dataByDivision: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  legendByDivision: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export default CardPortfolioStatistik;
