/* eslint-disable no-shadow */
/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-filename-extension */
import { styled } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import iconList from "assets/icons/general/icon-list.svg";
import ListCommon from "components/list/list-common";
import PlaceholderChart from "components/placeholder/chart-placeholder";
import TitleChart from "components/title/title-chart";
import TitleTable from "components/title/title-table";
import LoadingSpinner from "elements/loading/spinner";
import COLORS from "utils/constants/colors";
import { parseCurrency } from "utils/helpers/useFormater";

const CardTopIncomingStyle = styled("div")({
  backgroundColor: COLORS.WHITE,
  padding: "20px",
  borderRadius: "15px",
  boxShadow: `0px 3px 10px ${COLORS.BOX_SHADOW}`,
  flex: 1,
});

const SpaceStyle = styled("div")({
  height: "14px",
});

const CardPortfolioMerchantName = ({ list, titleText, data }) => {
  /* translations */
  const { t } = useTranslation();
  const translations = useMemo(
    () => ({
      titleTable: t("PORTOFOLIO.MERCHANT_NAME"),
    }),
    [t],
  );

  /* memo fetching */
  const DATA_PORTFOLIO_MERCHANT_NAME = useMemo(() => {
    if (list.isLoading) {
      return <LoadingSpinner height={220} />;
    }
    if (list.isSuccess) {
      return (
        <>
          {data.length < 1 && <PlaceholderChart height={250} />}
          {data.length > 0 &&
            data.map((listSale, index) => (
              <ListCommon
                number={index + 1}
                name={listSale.name}
                sum={parseCurrency(listSale.sum, { maximumFractionDigits: 10 })}
                fontWeight={600}
                key={JSON.stringify(index)}
              />
            ))}
        </>
      );
    }
  }, [list, data]);

  return (
    <CardTopIncomingStyle>
      <TitleChart icon={iconList} title={titleText} />
      <SpaceStyle />
      <TitleTable title1={translations.titleTable} marginLeft="33px" />
      {DATA_PORTFOLIO_MERCHANT_NAME}
    </CardTopIncomingStyle>
  );
};

CardPortfolioMerchantName.defaultProps = {
  list: [],
};

CardPortfolioMerchantName.propTypes = {
  list: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  titleText: PropTypes.string.isRequired,
};

export default CardPortfolioMerchantName;
