import ENDPOINT from "utils/constants/endpoint";
import { axiosPrivate } from "../axiosPrivate";

const getDataChartByDivision = async signal => {
  const { data: dataResponse, status } = await axiosPrivate.get(
    ENDPOINT.API_DASHBOARD.PORTOFOLIO.STATISTIC_BY_DIVISION,
    { signal },
  );
  if (status !== 200) throw new Error(`[getDataChartByDate] ${status}`);
  const { data } = dataResponse;

  const dataOthersInformation = Object.entries(data).reduce((items, [key, val]) => {
    if (key === "other_information") {
      return Object.entries(val).reduce(
        (prev, curr) => [
          ...prev,
          {
            name: curr[0],
            amount: curr[1],
          },
        ],
        [],
      );
    }
    return items;
  }, {});

  const dataSalesVolume = Object.entries(data).reduce((items, [key, val]) => {
    if (key === "sales_volume") {
      return Object.entries(val).reduce(
        (prev, curr, idx) => [
          ...prev,
          {
            name: curr[0]
              .replace(/_/g, " ")
              .trim()
              .replace(/(?<!\S)\w/g, letter => letter.toUpperCase()),
            amount: curr[1] * 1,
            marked: idx + 1,
          },
        ],
        [],
      );
    }
    return items;
  }, {});

  const dataCasa = Object.entries(data).reduce((items, [key, val]) => {
    if (key === "casa") {
      return Object.entries(val).reduce(
        (prev, curr, idx) => [
          ...prev,
          {
            name: curr[0]
              .replace(/_/g, " ")
              .trim()
              .replace(/(?<!\S)\w/g, letter => letter.toUpperCase()),
            amount: curr[1] * 1,
            marked: idx + 1,
          },
        ],
        [],
      );
    }
    return items;
  }, {});

  const dataTransaction = Object.entries(data).reduce((items, [key, val]) => {
    if (key === "transaction") {
      return Object.entries(val).reduce(
        (prev, curr, idx) => [
          ...prev,
          {
            name: curr[0]
              .replace(/_/g, " ")
              .trim()
              .replace(/(?<!\S)\w/g, letter => letter.toUpperCase()),
            amount: curr[1] * 1,
            marked: idx + 1,
          },
        ],
        [],
      );
    }
    return items;
  }, {});

  const parsedData = {
    othersInformation: dataOthersInformation,
    salesVolume: dataSalesVolume,
    casa: dataCasa,
    transaction: dataTransaction,
  };

  return parsedData;
};

const getDataChartByDate = async ({ type }, signal) => {
  const { data: dataResponse, status } = await axiosPrivate.get(
    `${ENDPOINT.BASEURL}/dashboard/portofolio/management/statistic/date?type=${
      type === "Sales Volume" ? "salesVolume" : type.toLowerCase()
    }`,
    { signal },
  );

  const { data } = dataResponse;

  if (status !== 200) throw new Error(`[getDataChartByDate] ${status}`);

  const parsedData = data
    .sort((a, b) => a.day_batch - b.day_batch)
    .reduce(
      (prev, curr) => [
        ...prev,
        {
          day_batch: curr.day_batch,
          dates: curr.dates,
          amount: curr.amount * 1,
        },
      ],
      [],
    );

  return parsedData;
};

export { getDataChartByDivision, getDataChartByDate };
