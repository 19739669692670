import { makeStyles } from "@material-ui/core";
import { Chart, Legend, LineAdvance, Tooltip } from "bizcharts";
import PropTypes from "prop-types";
import React from "react";
import COLORS from "utils/constants/colors";
import { SIZES } from "utils/constants/fonts";
import { maxScale } from "utils/helpers/useChartConfig";
import { parseCurrency } from "utils/helpers/useFormater";

const useStyles = makeStyles(() => ({
  wrapper: {
    paddingTop: "15px",
    padding: "10px",
    textAlign: "center",
    fontSize: "12px",
  },
  datesWrapper: {
    marginBottom: "8px",
    margintop: "5px",
    fontSize: SIZES.REGULAR_10,
    color: COLORS.GRAY_MEDIUM,
    fontWeight: 400,
  },
  valueWrapper: {
    fontSize: SIZES.REGULAR_10,
    fontWeight: 600,
    color: COLORS.DARK_HARD,
  },
}));

const ChartLineArea = ({ dataChart }) => {
  const classes = useStyles();
  const years = new Date().getFullYear();
  const months = new Date().getMonth();

  const getDaysInMonth = (month, year) => {
    const arr = new Array(31)
      .fill("")
      .map((v, i) => new Date(year, month - 1, i + 1))
      .filter(v => v.getMonth() === month - 1);
    return arr.length;
  };

  const scale = {
    /** @type {import('bizcharts').ScaleConfig} */
    amount: { min: 0, nice: true, max: maxScale(dataChart || {}) },
    /** @type {import('bizcharts').ScaleConfig} */
    day_batch: {
      min: 1,
      max: getDaysInMonth(months + 1, years),
      nice: true,
      tickInterval: 1,
    },
  };

  return (
    <Chart padding={[10, 20, 50, 40]} autoFit height={400} scale={scale} data={dataChart}>
      <LineAdvance
        day_batch
        shape="smooth"
        point="day_batch*amount"
        area
        position="day_batch*amount"
        color={COLORS.BLUE_MEDIUM}
      />
      <Tooltip position="top" showCrosshairs={false}>
        {(title, items) => {
          return (
            <div className={classes.wrapper}>
              <div className={classes.datesWrapper}>{items[0].data.dates ?? "-"}</div>
              <div className={classes.valueWrapper}>
                {parseCurrency(items[0].data.amount, { maximumFractionDigits: 3 }) ?? "-"}
              </div>
            </div>
          );
        }}
      </Tooltip>
      <Legend visible={false} position="bottom" />
    </Chart>
  );
};

ChartLineArea.defaultProps = {
  dataChart: [],
};

ChartLineArea.propTypes = {
  dataChart: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export default ChartLineArea;
