import { makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";

const useStyles = makeStyles({
  underMaintenanceContainer: {
    position: "relative",
  },
  wrapperUnderMaintenance: {
    width: "100%",
    position: "absolute",
    backgroundColor: "#ffffff80",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    top: 0,
    zIndex: 10,
    bottom: 0,
    height: "100%",
  },
});
const UnderMaintenance = ({ children }) => {
  const classes = useStyles();
  return (
    <div className={classes.underMaintenanceContainer}>
      {children}
      <div className={classes.wrapperUnderMaintenance}>
        <h1>Under Maintenance</h1>
      </div>
    </div>
  );
};

UnderMaintenance.defaultProps = {
  children: null,
};

UnderMaintenance.propTypes = {
  children: PropTypes.any,
};

export default UnderMaintenance;
