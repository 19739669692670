/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/no-array-index-key */
import { makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import DotIcon from "elements/icon/dot-icon";
import COLORS from "utils/constants/colors";

const useStyles = makeStyles(() => ({
  chartLegendWrapper: {
    margin: "22px 0 10px 0",
  },
  salesWrapper: {
    display: "flex",
    justifyContent: "center",
    gap: "40px",
    margin: "20px 0 5px",
  },
  othersWrapper: {
    display: "flex",
    justifyContent: "center",
    gap: "40px",
  },
  titleWrapper: {
    display: "flex",
    position: "relative",
  },
  imgWrapper: {
    position: "absolute",
    right: 0,
    left: "-100%",
    top: "38%",
    bottom: 0,
    marginRight: "10px",
  },
  typhographyStyle: {
    fontSize: "12px",
    color: COLORS.GRAY_PRIMARY,
  },
}));

const LegendPortfolioStatistics = ({ legend }) => {
  const classes = useStyles();

  /* translations */
  const { t } = useTranslation();
  const translations = useMemo(
    () => ({
      sales1: t("PORTOFOLIO.SALES_1"),
      sales2: t("PORTOFOLIO.SALES_2"),
      sales3: t("PORTOFOLIO.SALES_3"),
      sales4: t("PORTOFOLIO.SALES_4"),
      ecommerces: t("PORTOFOLIO.ECOMMERCES"),
      inderect: t("PORTOFOLIO.INDIRECT"),
      sleepingMerchant: t("PORTOFOLIO.SLEEPING_MERCHANT"),
      othersSales: t("PORTOFOLIO.OTHERS_SALES"),
      otherCasa: t("PORTOFOLIO.OTHERS_CASA"),
      otherTransaction: t("PORTOFOLIO.OTHERS_TRANSACTION"),
    }),
    [t],
  );

  return (
    <div className={classes.chartLegendWrapper}>
      <div className={classes.salesWrapper}>
        <div className={classes.titleWrapper}>
          <div className={classes.imgWrapper}>
            <DotIcon size={0.7} fill={COLORS.GREEN_HARD} />
          </div>
          <p className={classes.typhographyStyle}>{translations.sales1}</p>
        </div>
        <div className={classes.titleWrapper}>
          <div className={classes.imgWrapper}>
            <DotIcon size={0.7} fill={COLORS.BLUE_MEDIUM} />
          </div>
          <p className={classes.typhographyStyle}>{translations.sales2}</p>
        </div>
        <div className={classes.titleWrapper}>
          <div className={classes.imgWrapper}>
            <DotIcon size={0.7} fill={COLORS.RED_MEDIUM} />
          </div>
          <p className={classes.typhographyStyle}>{translations.sales3}</p>
        </div>
        <div className={classes.titleWrapper}>
          <div className={classes.imgWrapper}>
            <DotIcon size={0.7} fill={COLORS.PURPLE_HARD} />
          </div>
          <p className={classes.typhographyStyle}>{translations.sales4}</p>
        </div>
        <div className={classes.titleWrapper}>
          <div className={classes.imgWrapper}>
            <DotIcon size={0.7} fill={COLORS.SECONDARY_MEDIUM} />
          </div>
          <p className={classes.typhographyStyle}>{translations.ecommerces}</p>
        </div>
        <div className={classes.titleWrapper}>
          <div className={classes.imgWrapper}>
            <DotIcon size={0.7} fill={COLORS.CYAN} />
          </div>
          <p className={classes.typhographyStyle}>{translations.inderect}</p>
        </div>
      </div>
      <div className={classes.othersWrapper}>
        <div className={classes.titleWrapper}>
          <div className={classes.imgWrapper}>
            <DotIcon size={0.7} fill={COLORS.GRAY_DARK} />
          </div>
          <p className={classes.typhographyStyle}>
            {translations.sleepingMerchant} ({legend?.data?.othersInformation[0].amount})
          </p>
        </div>
        <div className={classes.titleWrapper}>
          <div className={classes.imgWrapper}>
            <DotIcon size={0.7} fill={COLORS.GRAY_DARK} />
          </div>
          <p className={classes.typhographyStyle}>
            {translations.othersSales} ({legend?.data?.othersInformation[1].amount})
          </p>
        </div>
        <div className={classes.titleWrapper}>
          <div className={classes.imgWrapper}>
            <DotIcon size={0.7} fill={COLORS.GRAY_DARK} />
          </div>
          <p className={classes.typhographyStyle}>
            {translations.otherCasa} ({legend?.data?.othersInformation[2].amount})
          </p>
        </div>
        <div className={classes.titleWrapper}>
          <div className={classes.imgWrapper}>
            <DotIcon size={0.7} fill={COLORS.GRAY_DARK} />
          </div>
          <p className={classes.typhographyStyle}>
            {translations.otherTransaction} ({legend?.data?.othersInformation[3].amount})
          </p>
        </div>
      </div>
    </div>
  );
};

LegendPortfolioStatistics.defaultProps = {
  legend: {},
};

LegendPortfolioStatistics.propTypes = {
  legend: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export default LegendPortfolioStatistics;
